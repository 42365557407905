@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

* {
  font-family: "DM Mono", monospace;
}

button .ant-btn-loading-icon {
  @apply inline-flex items-center justify-center !important;
}
